.infoContainer{
    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: column;
}
.iconContainer{
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
}
.img_button{
    height: 322px;
    width: 230px;
  }
.img_container{
    margin-top: 10px;
    margin-left: 2vw;
    /* height: 500px;
    width: 300px; */
    /* margin-right: 3%; */
    width: 230px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* align-content: center; */
    flex-wrap: nowrap;
}
