.img_container{
    margin-top: 10px;
    margin-left: 2vw;
    /* height: 500px;
    width: 300px; */
    /* margin-right: 3%; */
    width: 230px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* align-content: center; */
    flex-wrap: nowrap;
}


.img_button{
  height: 322px;
  width: 230px;
}
.img_button:hover{
cursor: pointer;
box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
}

.icon_container{
  height: 600px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}


