.banner {
    /* background-color: lightseagreen; */
    /* background: url('/images/banner.png') center center/cover no-repeat;  */
    /* min-height: 200px; */
    /* height: 200px; */
    width: 100%;
    max-height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    /* top: 0; */
    /* z-index: 999; */
  }