/* 

.backdrop {

  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
} */

.hero_container {
  /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
  /* background: url('/images/backdrop.png') center center/cover no-repeat;; */
  height: 91.5vh;
  /* width: 100%; */
  /* height: 90vw; */
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2); */
  /* object-fit: contain; */
  /* overflow: hidden; */
}

.hero_btns{
  margin-top: 70vh;
  overflow: visible;
  z-index: 1;
  position: fixed;
  /* width: 20%; */
}

@media screen and (max-width: 1024px) {
  .hero_btns{
    margin-top: 60vh;
  }
  .hero_container{
    width: 100%;
    height: 87vh;
  }
}

.bgimg{
  height: 100%;
  width: 100%;
  z-index: -1 ;
}
