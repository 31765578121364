.webinarContainer{
    width: 1400px;
    /* margin-top: 20px; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    justify-items: center;
    /* align-items: center; */
    align-content: center;
    flex-wrap: wrap;
    /* height: 100%; */
    /* justify-content: center; */
    /* justify-content: flex-start; */
    /* align-items: center; */
    /* height: 700px;   */
    /* overflow-y: scroll; */

}

@media screen and (max-width: 1399px) {
    .webinarContainer{
        width:700px;
    }
}

.webinarSubContainer1{
    margin-top: 2vh;
    flex: 1;
    min-width: 900px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* height: 700px;  */
    height: 100%;
    /* overflow-y: scroll; */
}
.webinarSubContainer2{
    margin-top: 2vh;
    flex: 1;
    min-width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* height: 700px;  */
    height: 100%;
    /* overflow-y: scroll; */
}
.sameRow{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
}

/* .webinarContainer{
    display: inline-block;
    align-items: center;
    justify-content: center;
    height: 700px;
    width: 100%;
    overflow-y: scroll;
} */