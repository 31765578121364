
.demoButton{
    margin-right: 10px;
}

.demoListContainer{
    flex: 1;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* height: 700px;  */
    height: 100%;
    /* overflow-y: scroll; */
}

/* .demoDetailContainer{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 500px; 
} */

.timeContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.projectButtonContainer{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}